import request from '../../libs/axios'

export function getExpencePayments(params) {
  return request({
    url:'/api/payment-expences',
    method:'get',
    params,
  })
}

export function postExpencePayments(data) {
  return request({
    url:'/api/payment-expences',
    method:'post',
    data,
  })
}

export function updateExpencePayments(data) {
  return request({
    url:`/api/payment-expences/${data.id}`,
    method:'put',
    data,
  })
}

export function getExpencePayment(id) {
  return request({
    url:`/api/payment-expences/${id}`,
    method:'get',
  })
}

export function getExpenceTypes(params) {
  return request({
    url: '/api/expence-types',
    method:'get',
    params,
  })
}
