<template>
  <div class="w-100">
    <b-overlay :show="loading">

      <b-card>
        <b-row>
          <b-col md="3">
            <label for="expenceType">{{$t('message.PaymentExpence')}}</label>
            <v-select :options="expenceTypes" label="name" v-model="filter.expenceType" @input="getDataByFilter">
            </v-select>
          </b-col>
          <b-col md="3">
            <label for="payment_type">{{$t("data.payment_type")}}</label>
            <v-select :options="paymentTypes" label="name_ru" v-model="filter.paymentType" @input="getDataByFilter">
            </v-select>
          </b-col>
          <b-col md="3">
            <label for="currency">{{$t('message.Currency')}}</label>
            <v-select :options="currencies" label="name" v-model="filter.currency" @input="getDataByFilter"></v-select>
          </b-col>
          <b-col md="3">
            <div class="d-flex justify-content-between">
              <b-button variant="warning" class="mt-2" @click="getDataByFilter">
                <b-icon icon="search"></b-icon>
              </b-button>
              <b-button variant="primary" @click="expenceModal = true" class="mt-2">
                <b-icon icon="plus"></b-icon> {{$t('message.Add')}}
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-card>
      <b-card class="mt-2">
        <b-table :fields="fields" :items="expences" responsive :busy="busy">
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </template>
          <template #cell(created_at)="{item}">
            {{item.created_at | dateTimeFormat}}
          </template>
          <template #cell(action)="{item}">
            <b-button variant="warning" size="sm" @click="handleEdit(item.id)">
              <b-icon icon="pencil"></b-icon>
            </b-button>
          </template>
        </b-table>
      </b-card>

      <b-pagination v-model="pagination.page" :total-rows="total" :per-page="pagination.pageSize"
        @input="fetchExpences"></b-pagination>
    </b-overlay>


    <b-modal v-model="expenceModal" size="lg" hide-footer no-close-on-backdrop>
      <ExpenceForm :id="id" @closeExpenceModal="closeExpenceModal" />
    </b-modal>
  </div>
</template>

<script>
  import {
    getExpencePayments,
    getExpenceTypes
  } from '@/api/payments/expences'
  import ExpenceForm from '@/views/payments/components/ExpenceForm'
  import {
    getPaymentTypes
  } from '@/api/payment-types/payment-types'

  export default {
    name: 'Expences',
    components: {
      ExpenceForm
    },
    data() {
      return {
        id: null,
        expenceTypes: [],
        paymentTypes: [],
        expences: [],
        expenceModal: false,
        filter: {
          expenceType: null,
          paymentType: null,
          currency: null,
        },
        filters: {
          expence_type_id: null,
          payment_type_id: null,
          currency_id: null,
        },
        pagination: {
          page: 1,
          pageSize: 20,
        },
        total: 1,
        fields: [{
            key: 'id',
            label: this.$t('message.ID')
          },
          {
            key: 'price',
            label: this.$t("message.Price")
          },
          {
            key: 'payment_type.name_ru',
            label: this.$t("message.Payments")
          },
          {
            key: 'expense_type.name',
            label: this.$t("message.PaymentExpence")
          },
          {
            key: 'branch.name',
            label: this.$t("message.Branches")
          },
          {
            key: 'author.full_name',
            label: this.$t("Author")
          },
          {
            key: 'created_at',
            label: this.$t("message.CreatedAt")
          },
          {
            key: 'action',
            label: this.$t('Action')
          },
        ],
        busy: true,
        loading: false,
      }
    },
    methods: {
      fetchExpenceTypes() {
        this.loading = true;
        getExpenceTypes().then(res => {
          this.expenceTypes = res.data.data;
          this.total = res.data.total;
          this.busy = false;
          this.loading = false;
        })
      },
      fetchPaymentType() {
        getPaymentTypes().then(res => {
          this.paymentTypes = res.data.data;
        })
      },
      fetchExpences() {
        getExpencePayments({
          ...this.filters,
          ...this.pagination
        }).then(res => {
          this.expences = res.data.data.data;
        })
      },
      closeExpenceModal() {
        this.expenceModal = false;
        this.fetchExpences();
        this.id = null;
      },
      handleAdd() {
        this.id = null;
        this.expenceModal = true;
      },
      handleEdit(id) {
        this.id = id;
        this.expenceModal = true;
      },
      getDataByFilter() {
        this.filters.expence_type_id = this.filter.expenceType ? this.filter.expenceType.id : null;
        this.filters.payment_type_id = this.filter.paymentType ? this.filter.paymentType.id : null;
        this.filters.currency_id = this.filter.currency ? this.filter.currency.id : null;
        this.fetchExpences();
      }
    },
    computed: {
      currencies() {
        return this.$store.state.settings.currencies;
      }
    },
    mounted() {
      this.fetchExpences()
      this.fetchExpenceTypes();
      this.fetchPaymentType()
      this.$store.dispatch('settings/fetchCurrencies')
    }
  }
</script>

<style scoped>

</style>