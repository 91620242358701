import request from '../../libs/axios'

export function getUsers(params){
  return request({
    url: '/api/users',
    method: 'get',
    params
  })
}

export function createUsersById(){
  return request({
    url:'/api/users',
    method:'get'
  })
}

export function getUsersById(data){
  return request({
    url: `/api/users/${data.id}`,
    method: 'get',
  })
}


export function addUsers(data){
  return request({
    url: '/api/users',
    method: 'post',
    data,
  })
}

export function editUsers(data){
  return request({
    url: `/api/users/${data.id}`,
    method: 'put',
    data,
  })
}

export function deleteUsers(data){
  return request({
    url: `/api/users/${data.id}`,
    method: 'delete',
  })
}
