<template>
  <div class="w-100">
    <b-overlay :show="loading">

    </b-overlay>
    <validation-observer ref="observer">
      <validation-provider #default="validationContext" name="Stars Count" rules="required|number">
        <label for="price">{{$t('message.Price')}}</label>
        <b-form-input v-model="form.price" type="number"></b-form-input>
        <span class="text-danger">
          {{ validationContext.errors[0] }}
        </span>
      </validation-provider>
      <div class="w-100 mt-2">
        <validation-provider #default="validationContext" name="Currencies" rules="required">
          <label for="currency">{{$t('message.Currency')}}</label>
          <v-select :options="currencies" label="name" v-model="currency"></v-select>
          <span class="text-danger">
            {{ validationContext.errors[0] }}
          </span>
        </validation-provider>
      </div>
      <div class="w-100 mt-2">
        <validation-provider #default="validationContext" :name="$t('message.PaymentExpence')" rules="required">
          <label for="expenceType">{{$t('message.PaymentExpence')}}</label>
          <v-select :options="expenceTypes" label="name" v-model="expenceType"
            @input="selectedType"
          ></v-select>
          <span class="text-danger">
            {{ validationContext.errors[0] }}
          </span>
        </validation-provider>
      </div>
      <div class="mt-2 w-100" v-if="partnersAction">
        <validation-provider #default="validationContext" :name="$t('message.Partners')" rules="required">
          <label>{{$t('message.Partners')}}</label>
          <v-select :options="partnersOptions" label="name" v-model="partnersType"></v-select>
          <span class="text-danger">
            {{ validationContext.errors[0] }}
          </span>
        </validation-provider>
      </div>
      <div class="mt-2 w-100" v-if="usersActions">
        <validation-provider #default="validationContext" :name="$t('message.Users')" rules="required">
          <label>{{$t('message.Users')}}</label>
          <v-select :options="usersOptions" label="name" v-model="usersType"></v-select>
          <span class="text-danger">
            {{ validationContext.errors[0] }}
          </span>
        </validation-provider>
      </div>
      <div class="mt-2 w-100">
        <validation-provider #default="validationContext" :name="$t('message.Payment_method')" rules="required">
          <label for="payment_type">{{$t('message.Payment_method')}}</label>
          <v-select :options="paymentTypes" label="name_ru" v-model="paymentType"></v-select>
          <span class="text-danger">
            {{ validationContext.errors[0] }}
          </span>
        </validation-provider>
      </div>
      <div class="w-100 mt-2">
        <label for="comment">{{$t('message.Comment')}}</label>
        <b-form-textarea placeholder="Textarea" rows="3" v-model="form.comment" />
      </div>

      <div class="mt-3 w-100 d-flex justify-content-end">
        <b-button variant="primary" :disabled="disabled" @click="saveSubmit">{{$t('Add')}}</b-button>
      </div>
    </validation-observer>
  </div>
</template>

<script>
  import {
    getExpencePayment,
    getExpenceTypes,
    postExpencePayments,
    updateExpencePayments
  } from '@/api/payments/expences'
  import {
    ValidationProvider,
    ValidationObserver,
    extend
  } from 'vee-validate'
  import {
    required,
  } from '@validations'
  import vSelect from 'vue-select'
  import * as rules from 'vee-validate/dist/rules';
  import {
    messages
  } from 'vee-validate/dist/locale/ru.json';
  import {
    getPaymentTypes
  } from '@/api/payment-types/payment-types'
  import {getPartners} from '@/api/partners/index';
  import {getUsers} from '@/api/users/users'

  Object.keys(rules).forEach(rule => {
    extend(rule, {
      ...rules[rule],
      message: messages[rule]
    });
  });

  export default {
    name: 'ExpenceForm',
    components: {
      ValidationProvider,
      ValidationObserver,
      vSelect,
    },
    props: {
      id: {
        default: null,
      }
    },
    data() {
      return {
        required,
        expenceTypes: [],
        paymentTypes: [],
        expenceType: null,
        paymentType: null,
        currency: null,
        form: {
          id: null,
          price: null,
          expense_type_id: null,
          currency_id: null,
          payment_type_id: null,
          comment: '',
        },
        partnersOptions: [],
        partnersType: null,
        partnersAction: false,
        priceAction: false,
        usersOptions: [],
        usersActions: false,
        usersType: null,
        disaledItem: false,
        loading: false,
      }
    },
    mounted() {
      this.fetchExpenceTypes();
      this.fetchPaymentType()
      this.$store.dispatch('settings/fetchCurrencies');
      this.getPartnersOptions();
      this.getUsersOptions();
    },
    computed: {
      currencies() {
        return this.$store.state.settings.currencies;
      }
    },
    methods: {
      fetchExpenceTypes() {
        getExpenceTypes().then(res => {
          this.expenceTypes = res.data.data;
        })
      },
      fetchPaymentType() {
        getPaymentTypes().then(res => {
          this.paymentTypes = res.data.data;
        })
      },
      fetchExpence() {
        getExpencePayment(this.id).then(res => {
          Object.assign(this.form, res.data.data);
          this.expenceType = res.data.data.expense_type;
          this.paymentType = res.data.data.payment_type;
          this.currency = res.data.data.currency;
          this.form.price = parseInt(this.form.price)
        })
      },
      saveSubmit() {
        const isValid = this.$refs.observer.validate();
        this.disabled = true;
        this.loading = true;
        setTimeout(() => {
          this.disabled = false;
          this.loading = false;
        }, 1000)
        if (isValid) {
          this.form.expense_type_id = this.expenceType.id;
          this.form.payment_type_id = this.paymentType.id;
          this.form.currency_id = this.currency.id;
  
          if(this.partnersType) {
            this.form.partners_id =this.partnersType.id;
          }
          if(this.usersType) {
            this.form.user_id = this.usersType.id;
          }

          if (this.id === null) {
            postExpencePayments(this.form).then(res => {
              this.$emit('closeExpenceModal')
            })
          } else {
            updateExpencePayments(this.form).then(res => {
              this.$emit('closeExpenceModal')
            })
          }
        }
      },
      getPartnersOptions() {
        getPartners().then( res => {
          this.partnersOptions = res.data.data.data
        })
      },
      getUsersOptions() {
        getUsers().then( res => {
          this.usersOptions = res.data.data
        })
      },
      selectedType(item) {
        if(item.id === 3) {
          this.partnersAction = true;
          this.usersActions = false;
        } else if(item.id === (2 || 4)) {
          this.usersActions = true;
          this.partnersAction = false;
        } else {
          this.usersActions = false;
          this.partnersAction = false;
        }
      }
    },
    watch: {
      id: {
        immediate: true,
        handler(val) {
          if (val) {
            this.fetchExpence()
          }
        }
      }
    }
  }
</script>
